import { groupBy, sumBy, update } from 'lodash-es'
import { LINE_ITEM_TYPES } from '@tenant/modules/tenant/estimates/utils/constants'
export const useTotalSummary = (fields) => {
    // Total net amount after discount
    const totalNetAmount = computed(() => {
        return sumBy(fields.value, (item) => item.value?.amount_net_total)
    })

    // Total net amount before discount
    const totalAmountBeforeDiscount = computed(() => {
        return sumBy(fields.value, (item) =>
            item.value.type === LINE_ITEM_TYPES.LINE_ITEM
                ? item.value?.amount_net_total
                : 0
        )
    })

    // Total discount line item
    const totalDiscount = computed(() =>
        sumBy(fields.value, (item) => {
            if (item.value.type === LINE_ITEM_TYPES.DISCOUNT)
                return -item.value?.discount
            if (item.value.type === LINE_ITEM_TYPES.SURCHARGE)
                return item.value?.discount

            return 0
        })
    )

    // Total VAT amount
    const totalVat = computed(() => getTotalVAT(fields.value))

    // VAT lines
    const vatAmounts = computed(() => {
        if (!fields.value.length) return null

        // Group all the line times by VAT rate
        const taxRates = groupBy(
            fields.value.filter(
                (field) => field.value.type === LINE_ITEM_TYPES.LINE_ITEM
            ),
            'value.tax_rate'
        )

        // Update VAT amount by VAT rate
        for (const key of Object.keys(taxRates)) {
            update(taxRates, key, (items) => {
                return getTotalVAT(items)
            })
        }
        return taxRates
    })

    const totalAmount = computed(() => {
        return totalNetAmount.value + totalVat.value
    })

    /**
     * Get total VAT amount of line items with the same VAT rate
     * @param items
     * @returns {number}
     */
    const getTotalVAT = (items) =>
        sumBy(items, (item) => {
            const totalVat =
                item.value.amount_net_total * (item.value.tax_rate / 100)
            const vatAmount = totalVat * ((totalDiscount.value ?? 0) / 100)

            return totalVat + vatAmount
        })

    return {
        totalNetAmount,
        totalAmountBeforeDiscount,
        totalVat,
        vatAmounts,
        totalAmount,
        getTotalVAT,
        totalDiscount,
    }
}
